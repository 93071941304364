import { AppBar, Box, styled, css, Stack } from '@mui/material';

import LogoBmimi from '@/assets/logos/bmimi.svg?url';
import LogoKlimaaktivMobil from '@/assets/logos/klimaaktiv-mobil.svg?url';
import { useIsPrinting } from '@/hooks/useIsPrinting';
import { transientOptions } from '@/utils/transient-options';

const StyledAppBar = styled(
  AppBar,
  transientOptions,
)<{ $isPrinting: boolean }>(
  ({ theme, $isPrinting }) => css`
    background-color: ${theme.palette.common.white};
    min-height: ${theme.spacing(10)};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};

    ${!$isPrinting &&
    css`
      ${theme.breakpoints.up('md')} {
        padding-left: ${theme.spacing(15)};
        padding-right: ${theme.spacing(15)};
        align-items: start;
      }
    `}
  `,
);

const StyledBmimi = styled(
  'img',
  transientOptions,
)<{ $isPrinting: boolean }>(
  ({ theme, $isPrinting }) => css`
    display: block;
    width: auto;

    ${!$isPrinting &&
    css`
      height: ${theme.spacing(6)};

      ${theme.breakpoints.up('sm')} {
        height: ${theme.spacing(8)};
      }

      ${theme.breakpoints.up('md')} {
        height: ${theme.spacing(10)};
      }

      @media print {
        height: ${theme.spacing(6)};
      }
    `}

    ${$isPrinting &&
    css`
      height: ${theme.spacing(6)};
    `}
  `,
);

const StyledKlimaAktiv = styled(
  'img',
  transientOptions,
)<{ $isPrinting: boolean }>(
  ({ theme, $isPrinting }) => css`
    display: block;
    width: auto;

    ${!$isPrinting &&
    css`
      height: ${theme.spacing(5)};

      ${theme.breakpoints.up('sm')} {
        height: ${theme.spacing(6)};
      }

      ${theme.breakpoints.up('md')} {
        height: ${theme.spacing(7)};
      }

      @media print {
        height: ${theme.spacing(6)};
      }
    `}

    ${$isPrinting &&
    css`
      height: ${theme.spacing(6)};
    `}
  `,
);

export function Header() {
  const { isPrinting } = useIsPrinting();

  return (
    <Box>
      <StyledAppBar position="static" $isPrinting={isPrinting}>
        <Stack direction="row" alignItems="center" gap={{ xs: 2, sm: 4 }}>
          <StyledBmimi
            src={LogoBmimi}
            width="158"
            height="51"
            alt="Bundesministerium für Innovation, Mobilität und Infrastruktur"
            $isPrinting={isPrinting}
          />
          <StyledKlimaAktiv
            src={LogoKlimaaktivMobil}
            width="133"
            height="48"
            alt="klimaaktiv mobil"
            $isPrinting={isPrinting}
          />
        </Stack>
      </StyledAppBar>
    </Box>
  );
}
